import styled from "styled-components";
import * as React from "react";
import { useTranslation } from 'react-i18next';
import { FaReact } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import { FaPhp } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa";
import { SiJavascript } from "react-icons/si";

const Container = styled.div`
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;

  @media (max-width: 1024px) {
    max-width: 1024px !important;
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    max-width: 767px !important;
  }
`;

const ContainerTwo = styled.div`
  position: relative;
  min-height: 1px;
  display: flex;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContainerThree = styled.div`
  padding: 10px;
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
`;

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const SkillsTitle = styled.div`
  text-align: center;
  margin-bottom: 20px;
  padding: 0px 250px 25px 250px;
  width: 100%;

  @media (max-width: 767px) {
    padding: 0px 0px 0px 0px;
  }

  @media (max-width: 1024px) {
    padding: 0px 80px 0px 80px;
  }

  & h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0px;
    color: #9a9a9a;
    background: linear-gradient(97.13deg, #E9E9E9 0%, #515151 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: #1A1A1A;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #242424;
  color: rgb(138, 138, 138);
  padding: 10px;
  display: flex;
  flex-direction: column;

  & h1 {
    font-size: 22px;
    width: fit-content;
    color: #9a9a9a;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

const WrapperText = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Divider = styled.div`
  height: 40%;
  width: 1px;
  background-color: rgb(36, 36, 36);
  margin: 0 2%;
  box-sizing: border-box;

  @media only screen and (max-width: 600px) {
    width: 50%;
    height: 1px;
    margin: 30px 0;
  }
`;

const LangageRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  &:first-of-type {
    padding-bottom: 10px;
  }
`;

const LangageCard = styled.div`
  width: 4em;
  padding: 10px 10px 5px 10px;
  color: rgb(138, 138, 138);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & svg {
    width: 3em;
    height: 3em;
  }
`;

const CardHtml = styled(LangageCard)`
  & svg:hover {
    color: #f06529;
  }
`;

const CardCss = styled(LangageCard)`
  & svg:hover {
    color: #2965f1;
  }
`;

const CardJs = styled(LangageCard)`
  & svg:hover {
    color: #F0DB4F;
  }
`;

const CardPhp = styled(LangageCard)`
  & svg:hover {
    color: #8993be;
  }
`;

const CardReact = styled(LangageCard)`
  & svg:hover {
    color: #61dbfb;
  }
`;

const CardNode = styled(LangageCard)`
  & svg:hover {
    color: #3c873a;
  }
`;

function Skills() {

  const { t } = useTranslation();

  return (
    <Container>
      <ContainerTwo>
        <ContainerThree>
          <SkillsTitle>
            <h2>{t('skill.title')}</h2>
          </SkillsTitle>
          <Section>
            <Wrapper>
              <h1>{t('skill.section1')}</h1>
              <LangageRow>
                <CardHtml>
                  <FaHtml5 />
                  <span>HTML5</span>
                </CardHtml>
                <CardCss>
                  <FaCss3Alt />
                  <span>CSS3</span>
                </CardCss>
                <CardJs>
                  <SiJavascript />
                  <span>Javascript</span>
                </CardJs>
              </LangageRow>
              <LangageRow>
                <CardPhp>
                  <FaPhp />
                  <span>PHP</span>
                </CardPhp>
                <CardReact>
                  <FaReact />
                  <span>ReactJS</span>
                </CardReact>
                <CardNode>
                  <FaNodeJs />
                  <span>NodeJS</span>
                </CardNode>
              </LangageRow>
            </Wrapper>
            <Divider />
            <Wrapper>
              <h1>{t('skill.section2')}</h1>
              <WrapperText>
                <p>{t('skill.phrase1')}</p>
                <p>{t('skill.phrase2')}</p>
                <p>{t('skill.phrase3')}</p>
                <p>{t('skill.phrase4')}</p>
              </WrapperText>
            </Wrapper>
          </Section>
        </ContainerThree>
      </ContainerTwo>
    </Container>
  );
}

export default Skills;
import styled from "styled-components";
import * as React from "react";
import {useTranslation} from 'react-i18next';

const Container = styled.div`
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const TitleContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 0px 300px 25px 300px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 0px 0px 0px 0px;
  }

  @media (max-width: 1024px) {
    padding: 0px 80px 0px 80px;
  }

  & h1 {
    color: #9a9a9a;
    font-family: "Outfit", Sans-serif;
    font-size: 56px;
    font-weight: 400;
    letter-spacing: 0px;
    word-spacing: 0px;
    padding: 0;
    margin: 0;
    background: linear-gradient(97.13deg, #E9E9E9 0%, #515151 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 767px) {
      font-size: 34px;
      line-height: 1.1em;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  position: relative;

  & a {
    cursor: pointer;
    font-family: "Outfit", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    fill: #9A9A9A;
    color: #9A9A9A;
    background-color: #1A1A1A;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #242424;
    padding: 18px 45px 18px 45px;
    width: auto;
    box-shadow: none;
    text-decoration: none;
    display: inline-block;
    line-height: 1;
    border-radius: 3px;
    text-align: center;
    box-sizing: border-box;
  }
`;

function Contact() {

    const { t, i18n } = useTranslation();

    return (
        <Container>
            <Wrapper>
                <TitleContainer>
                    <h1>{t('contact.title')}</h1>
                </TitleContainer>
                <ButtonContainer>
                    <a href={"mailto:contact@valentin-lerouge.fr"} target={"_blank"} rel="noreferrer">{t('contact.button')}</a>
                </ButtonContainer>
            </Wrapper>
        </Container>
    );
}

export default Contact;
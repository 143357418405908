import {createTheme} from "@mui/material";

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#FC619C',
        },
    },
    typography: {
        h1: {
            '@media (max-width:600px)': {
                fontSize: '3rem',
            },
        },
    },
});
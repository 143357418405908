import styled from "styled-components";
import * as React from "react";
import {IconContext} from "react-icons";
import {
    SiLinkedin,
    SiGithub,
    SiMinutemailer
} from "react-icons/si";
import SwitchLangage from "./SwitchLangage";

const ContainerNavbar = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DisplayNavbar = styled.div`
  height: fit-content;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const PartNavbar = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  color: #8A8A8A;
  font-family: "Outfit", Sans-serif;
  font-size: 26px;
  font-weight: 400;

  @media (max-width: 767px) {
    font-size: 19px;
  }
`;

const NavbarLink = styled.a`
  box-shadow: none;
  text-decoration: none;
  box-sizing: border-box;
  color: #9a9a9a;

  &:hover {
    color: #dcdcdc;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const NavbarIcon = styled.a`
  display: none;
  box-shadow: none;
  text-decoration: none;
  box-sizing: border-box;

  &:hover {
    fill: #dcdcdc;
  }

  @media (max-width: 767px) {
    display: contents;
  }
`;

function Navbar() {

    return (
        <ContainerNavbar>
            <DisplayNavbar>
                <PartNavbar>
                    <Logo>
                        Valentin Lerouge
                    </Logo>
                </PartNavbar>
                <PartNavbar>
                    <NavbarLink href={"https://www.linkedin.com/in/valentin-lerouge-b623b51a4/"}
                                target={"_blank"}>
                        Linkedin
                    </NavbarLink>
                    <NavbarIcon href={"https://www.linkedin.com/in/valentin-lerouge-b623b51a4/"}
                                target={"_blank"}>
                        <IconContext.Provider value={{color: 'rgb(154, 154, 154)', size: '16px'}}>
                            <SiLinkedin/>
                        </IconContext.Provider>
                    </NavbarIcon>
                    &nbsp; &nbsp; /  &nbsp; &nbsp;
                    <NavbarLink href={"https://github.com/DopeCloudd"} target={"_blank"}>Github</NavbarLink>
                    <NavbarIcon href={"https://github.com/DopeCloudd"} target={"_blank"}>
                        <IconContext.Provider value={{color: 'rgb(154, 154, 154)', size: '16px'}}>
                            <SiGithub/>
                        </IconContext.Provider>
                    </NavbarIcon>
                    &nbsp; &nbsp; /  &nbsp; &nbsp;
                    <NavbarLink href={"mailto:contact@valentin-lerouge.fr"} target={"_blank"}>Mail</NavbarLink>
                    <NavbarIcon href={"mailto:contact@valentin-lerouge.fr"} target={"_blank"}>
                        <IconContext.Provider value={{color: 'rgb(154, 154, 154)', size: '16px'}}>
                            <SiMinutemailer/>
                        </IconContext.Provider>
                    </NavbarIcon>
                    &nbsp; &nbsp; /
                    <SwitchLangage/>
                </PartNavbar>
            </DisplayNavbar>
        </ContainerNavbar>
    );
}

export default Navbar;
//Theme
import styled, {keyframes} from "styled-components";
//Components
import Navbar from "./components/Navbar";
//Sections
import Landing from "./section/Landing";
import Timeline from "./section/Timeline";
import Skills from "./section/Skills";
import Projects from "./section/Projects";
import Contact from "./section/Contact";
import Footer from "./components/Footer";
// Background
import BgDot from "./img/BG-Tx-1.svg";

//Animation apparition
const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
//Wrapper pour les transitions
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  animation: ${appear} 1s;
  -moz-animation: ${appear} 1s; /* Firefox */
  -webkit-animation: ${appear} 1s; /* Safari et Chrome */
  -o-animation: ${appear} 1s; /* Opera */
`;

const BackgroundFixed = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("${BgDot}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
//Positionnement de la navbar
const FixedNavbar = styled.div`
  z-index: 99;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
//Ligne sous la navbar
const NavbarSeparator = styled.span`
  border-bottom: 1px solid #242424;
  height: 1px;
  width: 80%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;
//Container de la section Landing
const LandingContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 0px 70px 0px;
`;
//Container de la section Timeline
const TimelineContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

//Container de la section Timeline
const SkillsContainer = styled.section`
  padding: 130px 0px 100px 0px;
  width: 100%;
`;

//Container de la section Projects
const ProjectsContainer = styled.section`
  padding: 60px 0px 100px 0px;
  width: 100%;
`;

const Separator = styled.div`
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  box-sizing: border-box;

  & div {
    width: 100%;
    padding: 10px;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-content: flex-start;
    box-sizing: border-box;
  }
`;

const Diviser = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;

  & span {
    border-top: 1px solid #242424;
    width: 100%;
    display: flex;
    margin: 0;
    direction: ltr;
    box-sizing: border-box;
  }
`;

const ContactContainer = styled.section`
  padding: 100px 0px 100px 0px;
`;

const FooterContainer = styled.section`
  border-radius: 0px 0px 0px 0px;
  border-style: none;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 0px 0px 20px 0px;
`;

function App() {
    return (
      <>
            <BackgroundFixed/>
            <Container>
                <FixedNavbar> 
                    <Navbar/>
                    <NavbarSeparator/>
                </FixedNavbar>
                <LandingContainer>
                    <Landing/>
                </LandingContainer>
                <TimelineContainer>
                    <Timeline/>
                </TimelineContainer>
                <SkillsContainer> 
                    <Skills/>
                </SkillsContainer> 
                <section>
                    <Separator>
                        <div>
                            <Diviser>
                                <span></span>
                            </Diviser>
                        </div>
                    </Separator>
                </section>
                <ProjectsContainer>
                  <Projects/>
                </ProjectsContainer>
                <section>
                    <Separator>
                        <div>
                            <Diviser>
                                <span></span>
                            </Diviser>
                        </div>
                    </Separator>
                </section>
                <ContactContainer>
                    <Contact/>
                </ContactContainer>
                <section>
                    <Separator>
                        <div>
                            <Diviser>
                                <span></span>
                            </Diviser>
                        </div>
                    </Separator>
                </section>
                <FooterContainer>
                    <Footer/>
                </FooterContainer>
            </Container>
        </>
    );
}

export default App;

import styled from "styled-components";
import * as React from "react";
import { useTranslation } from 'react-i18next';
//Logo
import VinciLogo from "../img/vinci_logo.png";
import GarnemLogo from "../img/garnem_logo.png";
import WelanceLogo from "../img/logo_welance.png";

const Container = styled.div`
  background-color: #1A1A1A;
  border-style: solid;
  border-width: 1px 0px 1px 0px;
  border-color: #242424;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

const TimelineBox = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0px 30px 0px;

  @media (max-width: 767px) {
    width: 160px;
  }
`;

const TimelineBoxLogo = styled.img`
  width: 80px;
  height: 45px;
`;

const TimelineBoxText = styled.div`
  color: rgba(233, 233, 233, 0.7);
  font-family: Outfit, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const TimelineBoxTitle = styled(TimelineBoxText)`
  font-weight: bold;
`;

function Timeline() {

  const { t } = useTranslation();

  return (
    <Container>
      <TimelineBox>
        <TimelineBoxLogo src={VinciLogo} />
        <TimelineBoxTitle>
          Vinci Construction France
        </TimelineBoxTitle>
        <TimelineBoxText>
          {t('vinci.date')}
        </TimelineBoxText>
        <TimelineBoxText>
          {t('vinci.poste')}
        </TimelineBoxText>
        <TimelineBoxText>
          {t('vinci.description')}
        </TimelineBoxText>
      </TimelineBox>
      <TimelineBox>
        <TimelineBoxLogo src={GarnemLogo} />
        <TimelineBoxTitle>
          GARNEM SAS
        </TimelineBoxTitle>
        <TimelineBoxText>
          {t('garnem.date')}
        </TimelineBoxText>
        <TimelineBoxText>
          {t('garnem.poste')}
        </TimelineBoxText>
        <TimelineBoxText>
          {t('garnem.description')}
        </TimelineBoxText>
      </TimelineBox>
      <TimelineBox>
        <TimelineBoxLogo src={WelanceLogo} />
        <TimelineBoxTitle>
          WELANCE
        </TimelineBoxTitle>
        <TimelineBoxText>
          {t('welance.date')}
        </TimelineBoxText>
        <TimelineBoxText>
          {t('welance.poste')}
        </TimelineBoxText>
        <TimelineBoxText>
          {t('welance.description')}
        </TimelineBoxText>
      </TimelineBox>
    </Container>
  );
}

export default Timeline;
import styled from "styled-components";
import * as React from "react";
import { useTranslation } from 'react-i18next';
//Profile picture
import Profile from "../img/profile.png";
import ProfileImage from "../img/profile_picture.png";

const Container = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProfilePicture = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
  filter: grayscale(1);
`;

const LandingTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  fill: #9A9A9A;
  color: #9A9A9A;
  margin-bottom: 20px;
`;

const LandingText = styled.h1`
  font-size: 56px;
  font-weight: 400;
  letter-spacing: 0;
  word-spacing: 0;
  color: #9a9a9a;
  line-height: 64px;
  padding: 0;
  margin: 0;
  background: linear-gradient(97.13deg, #E9E9E9 0%, #515151 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  width: 100%;

  @media (max-width: 767px) {
    font-size: 33px;
    line-height: 1.1em;
  }
`;

function Landing() {

  const { t, i18n } = useTranslation();

  return (
    <Container>
      <ProfilePicture src={ProfileImage} />
      <LandingTitle>{t('main.hello')}</LandingTitle>
      <LandingText dangerouslySetInnerHTML={{ __html: t('main.title') }}></LandingText>
    </Container>
  );
}

export default Landing;
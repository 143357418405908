import styled from "styled-components";
import * as React from "react";
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;

  @media (max-width: 1024px) {
    max-width: 1024px !important;
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    max-width: 767px !important;
  }
`;

const ContainerTwo = styled.div`
  position: relative;
  min-height: 1px;
  display: flex;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContainerThree = styled.div`
  padding: 10px;
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
`;

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 20px;
  padding: 0px 250px 25px 250px;
  width: 100%;

  @media (max-width: 767px) {
    padding: 0px 0px 0px 0px;
  }

  @media (max-width: 1024px) {
    padding: 0px 80px 0px 80px;
  }

  & h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0px;
    color: #9a9a9a;
    background: linear-gradient(97.13deg, #E9E9E9 0%, #515151 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: #1A1A1A;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #242424;
  color: rgb(138, 138, 138);
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  & h1 {
    font-size: 22px;
    width: fit-content;
    color: #9a9a9a;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

const Divider = styled.div`
  height: 40%;
  width: 1px;
  background-color: rgb(36, 36, 36);
  margin: 0 2%;
  box-sizing: border-box;

  @media only screen and (max-width: 600px) {
    width: 50%;
    height: 1px;
    margin: 30px 0;
  }
`;

const TextEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;

  & a {
    text-decoration: underline;
  }
`;

function Projects() {

  const { t } = useTranslation();

  const openLinkWelance = () => {
    window.open("https://welance.valentin-lerouge.fr/", '_blank', 'noopener,noreferrer');
  }

  const openLinkMyprottracker = () => {
    window.open("https://myprottracker.com/", '_blank', 'noopener,noreferrer');
  }

  return (
    <Container>
      <ContainerTwo>
        <ContainerThree>
          <Title>
            <h2>{t('projects.title')}</h2>
          </Title>
          <Section>
            <Wrapper onClick={ () => openLinkWelance()}>
              <h1>{t('projects.section1')}</h1>
              <p>
                Stack : ReactJS (Styled-Components, Framer-Motion), NodeJS (ExpressJS, Nodemailer)
              </p>
              <br/>
              <p>
                Description : {t('projects.description1')}
              </p>
              <TextEnd>
                <a>Link</a>
              </TextEnd>
            </Wrapper>
            <Divider />
            <Wrapper onClick={ () => openLinkMyprottracker()}>
              <h1>{t('projects.section2')}</h1>
              <p>
                Stack : ReactJS (MUI, Styled-Components, i18next, Axios, Redux), NodeJS (ExpressJS, CORS, Puppeteer, Nodemailer, Sequelize), MySQL
              </p>
              <br/>
              <p>
                Description : {t('projects.description2')}
              </p>
              <TextEnd>
                <a>Link</a>
              </TextEnd>
            </Wrapper>
          </Section>
        </ContainerThree>
      </ContainerTwo>
    </Container>
  );
}

export default Projects;
import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Outfit', sans-serif;
  }

  html, body, #root {
    background-color: #161616;
  }
`;

export default GlobalStyle;